import React from "react"
import style from "./layout.module.scss"
import { Helmet } from "react-helmet"

export default function Layout({ children, title }) {
  return (
    <div className={style.main}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || "Good Behavior Films"}</title>
        <meta
          name="description"
          content="Good Behavior Films"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://goodbehaviorfilms.com/" />
      </Helmet>
      {children}
    </div>
  )
}
